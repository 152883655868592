import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import styles from "./Loading.module.css"

export default function Loading({ text = "Laddar..." }) {
  return (
    <div className={styles.wrapper}>
      {text}&nbsp;
      <FontAwesomeIcon icon={faSpinner} spin />
    </div>
  )
}
