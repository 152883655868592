import React from "react"
import { Router } from "@reach/router"
import Layout from "../components/Layout"
import StartList from "../admin-pages/StartList"
import Waiting from "../admin-pages/Waiting"
import Login from "../components/login"
import PrivateRoute from "../components/PrivateRoute"
import { Link } from "gatsby"
import styles from "./admin.module.css"
import { logout } from "../services/auth"
import Overview from "../admin-pages/Overview"

const Admin = () => (
  <Layout>
    <nav className={styles.adminMenu}>
      {[
        { name: "Översikt", to: "/admin/oversikt" },
        { name: "Startlista", to: "/admin/startlista" },
        { name: "Obekräftade", to: "/admin/obekraftade" },
      ].map(({ name, to }) => (
        <Link
          key={to}
          to={to}
          className={styles.adminLink}
          activeClassName={styles.current}
        >
          {name}
        </Link>
      ))}
      <a href="#" onClick={logout} className={styles.adminLink}>
        Logga ut
      </a>
    </nav>
    <Router>
      <PrivateRoute path="/admin/oversikt" component={Overview} />
      <PrivateRoute path="/admin/startlista" component={StartList} />
      <PrivateRoute path="/admin/obekraftade" component={Waiting} />
      <Login path="/admin/login" />
    </Router>
  </Layout>
)

export default Admin
