import React, { useEffect, useState } from "react"
import { getUser } from "../services/auth"
import Content from "../components/Content"
import styles from "./Overview.module.css"
import Loading from "../components/Loading"
import { groups, groupSize } from "../config"

export default function Overview() {
  const [state, setState] = useState("idle")
  const [starting, setStarting] = useState([])
  const [waiting, setWaiting] = useState([])

  useEffect(() => {
    setState("loading")
    const fetchWaiting = window
      .fetch(`/api/unapproved?token=${getUser().token}`)
      .then(response => response.json())
      .then(participants => {
        setWaiting(participants)
      })
      .catch(err => {
        console.error(err)
      })
    const fetchStarting = window
      .fetch(`/api/startlist?token=${getUser().token}`)
      .then(response => response.text())
      .then(text => {
        setStarting(text.split("\n").map(l => l.split(";")))
      })
      .catch(err => {
        console.error(err)
      })

    Promise.all([fetchStarting, fetchWaiting])
      .then(() => setState("idle"))
      .catch(() => setState("idle"))
  }, [])

  const groupCounts = starting.reduce(
    (counts, [startNumber]) => {
      counts[Math.floor(startNumber / 100) - 1]++
      return counts
    },
    [0, 0, 0]
  )

  return (
    <Content>
      {state === "loading" ? (
        <Loading />
      ) : (
        <div className={styles.stats}>
          <Stat
            number={starting.length}
            total={groups.length * groupSize}
            title="Bekräftade anmälda"
          />
          {groupCounts.map((c, i) => (
            <Stat
              key={i}
              number={c}
              total={groupSize}
              title={`Startgrupp ${i + 1}`}
            />
          ))}
          <Stat number={waiting.length} title="Obekräftade anmälda" />
        </div>
      )}
    </Content>
  )
}

function Stat({ number, total, title }) {
  const hasTotal = total !== undefined
  return (
    <div className={styles.stat}>
      <div className={styles.statNumber}>
        <em>{number}</em>
        {hasTotal && <>&nbsp;&nbsp;/&nbsp;{total}</>}
      </div>
      {hasTotal && (
        <div className={styles.progressWrapper}>
          <div
            className={styles.progress}
            style={{ width: `${(number / total) * 100}%` }}
          />
        </div>
      )}
      <div className={styles.statTitle}>{title}</div>
    </div>
  )
}
