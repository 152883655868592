import React from "react"
import { navigate } from "gatsby"
import { handleLogin, isLoggedIn } from "../services/auth"
import Content from "./Content"
import Button from "./Button"
import styles from "./login.module.css"

class Login extends React.Component {
  state = {
    username: ``,
    password: ``,
  }

  handleUpdate = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleSubmit = event => {
    event.preventDefault()
    return handleLogin(this.state)
  }

  render() {
    if (isLoggedIn()) {
      navigate(`/admin/oversikt`)
    }

    return (
      <Content>
        <h1>Logga in</h1>
        <form
          method="post"
          onSubmit={event => {
            this.handleSubmit(event).then(
              success => success && navigate(`/admin/oversikt`)
            )
          }}
          className={styles.form}
        >
          <label>Användarnamn</label>
          <input type="text" name="username" onChange={this.handleUpdate} />
          <label>Lösenord</label>
          <input type="password" name="password" onChange={this.handleUpdate} />
          <Button type="submit">Logga in</Button>
        </form>
      </Content>
    )
  }
}

export default Login
