import React, { useEffect, useState } from "react"
import { getUser } from "../services/auth"
import Content from "../components/Content"
import styles from "./StartList.module.css"
import Loading from "../components/Loading"
import { format, parseISO } from "date-fns"
import { groups } from "../config"

export default function StartList() {
  const [state, setState] = useState("idle")
  const [participants, setParticipants] = useState([])

  useEffect(() => {
    setState("loading")
    window
      .fetch(`/api/startlist?token=${getUser().token}`)
      .then(response => response.text())
      .then(text => {
        setParticipants(text.split("\n").map(l => l.split(";")))
        setState("idle")
      })
      .catch(err => {
        console.error(err)
        setState("idle")
      })
  }, [])

  return (
    <Content>
      <p>
        {state === "loading" ? (
          <Loading />
        ) : (
          <>
            <em>{participants.length}</em> bekräftade anmälda.
          </>
        )}
      </p>
      <table className={styles.startList}>
        <thead>
          <tr>
            <th>Startnummer</th>
            <th>Startgrupp</th>
            <th>Namn</th>
            <th>Klubb</th>
            <th>E-post</th>
            <th>Telefon</th>
            <th>Veg.</th>
            <th>Glutenf.</th>
            <th>Datum</th>
          </tr>
        </thead>
        <tbody>
          {participants.map(
            ([
              startnumber,
              group,
              name,
              club,
              email,
              phoneNumber,
              diet1,
              diet2,
              registrationDate,
            ]) => (
              <tr key={startnumber}>
                <td>{startnumber}</td>
                <td>{groups[group - 1][2]}</td>
                <td>{name}</td>
                <td>{club}</td>
                <td>{email}</td>
                <td>{phoneNumber}</td>
                <td>{diet1 !== "meat" ? "X" : ""}</td>
                <td>{diet2 !== "gluten" ? "X" : ""}</td>
                <td>
                  {format(parseISO(registrationDate), "yyyy-MM-dd HH:mm")}
                </td>
              </tr>
            )
          )}
        </tbody>
      </table>
    </Content>
  )
}
