export const isBrowser = () => typeof window !== "undefined"

export const getUser = () =>
  isBrowser() && window.localStorage.getItem("gatsbyUser")
    ? JSON.parse(window.localStorage.getItem("gatsbyUser"))
    : {}

const setUser = user =>
  window.localStorage.setItem("gatsbyUser", JSON.stringify(user))

export const handleLogin = state => {
  return window
    .fetch("/api/token", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(state),
    })
    .then(
      response =>
        response.ok &&
        response.json().then(user => {
          setUser(user)
          return true
        })
    )
}

export const isLoggedIn = () => {
  const user = getUser()

  return !!user.token
}

export const logout = () => {
  setUser({})
}
