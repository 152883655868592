import React, { useEffect, useState } from "react"
import { getUser } from "../services/auth"
import Content from "../components/Content"
import styles from "./Waiting.module.css"
import Loading from "../components/Loading"

export default function StartList() {
  const [state, setState] = useState("idle")
  const [participants, setParticipants] = useState([])

  useEffect(refresh, [])

  return (
    <Content>
      <>
        {state === "loading" ? (
          <Loading />
        ) : (
          <p>
            <em>{participants.length}</em> obekräftade anmälda.
          </p>
        )}
        {participants.length > 0 && (
          <table className={styles.startList}>
            <thead>
              <tr>
                <th>Namn</th>
                <th>Klubb</th>
                <th>Telefon</th>
                <th>E-post</th>
              </tr>
            </thead>
            <tbody>
              {participants.map(({ id, name, club, phone, email }) => (
                <tr key={`${id}-$`}>
                  <td>{name}</td>
                  <td>{club}</td>
                  <td>{phone}</td>
                  <td>{email}</td>
                  <td>
                    <div className="flex flex-col">
                      <button
                        onClick={() => approve(id).then(refresh)}
                        className={styles.button}
                        type="button"
                      >
                        Godkänn
                      </button>
                      <button
                        onClick={() => remove(id).then(refresh)}
                        className={`${styles.button} mt-2`}
                        type="button"
                      >
                        Ta bort
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </>
    </Content>
  )

  function action(url, id) {
    setState("loading")
    return window
      .fetch(url + "?token=" + getUser().token, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ id }),
      })
      .then(response => {
        setState("idle")
        if (response.ok) {
          return true
        } else {
          throw new Error(`Unexpected response ${response.status}`)
        }
      })
  }

  function approve(id) {
    return action("/api/approve", id)
  }

  function remove(id) {
    return action("/api/remove", id)
  }

  function refresh() {
    setState("loading")
    window
      .fetch(`/api/unapproved?token=${getUser().token}`)
      .then(response => response.json())
      .then(participants => {
        setParticipants(participants)
        setState("idle")
      })
      .catch(err => {
        console.error(err)
        setState("idle")
      })
  }
}
